var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - List Referrals" }
      }),
      _c("gov-back-link", { attrs: { to: { name: "dashboard" } } }, [
        _vm._v("Back to dashboard")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "full" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Referrals")
                  ]),
                  _c(
                    "gov-grid-row",
                    [
                      _c(
                        "gov-grid-column",
                        { attrs: { width: "two-thirds" } },
                        [
                          _c(
                            "ck-table-filters",
                            { on: { search: _vm.onSearch } },
                            [
                              _c(
                                "gov-form-group",
                                [
                                  _c(
                                    "gov-label",
                                    { attrs: { for: "filter[reference]" } },
                                    [_vm._v("Reference no.")]
                                  ),
                                  _c("gov-input", {
                                    attrs: {
                                      id: "filter[reference]",
                                      name: "filter[reference]",
                                      type: "search"
                                    },
                                    model: {
                                      value: _vm.filters.reference,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "reference", $$v)
                                      },
                                      expression: "filters.reference"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "extra-filters" },
                                [
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        {
                                          attrs: { for: "filter[service_name]" }
                                        },
                                        [_vm._v("Service name")]
                                      ),
                                      _c("gov-input", {
                                        attrs: {
                                          id: "filter[service_name]",
                                          name: "filter[service_name]",
                                          type: "search"
                                        },
                                        model: {
                                          value: _vm.filters.service_name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "service_name",
                                              $$v
                                            )
                                          },
                                          expression: "filters.service_name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        {
                                          attrs: {
                                            for: "filter[organisation_name]"
                                          }
                                        },
                                        [_vm._v("Organisation name")]
                                      ),
                                      _c("gov-input", {
                                        attrs: {
                                          id: "filter[organisation_name]",
                                          name: "filter[organisation_name]",
                                          type: "search"
                                        },
                                        model: {
                                          value: _vm.filters.organisation_name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "organisation_name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.organisation_name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        { attrs: { for: "filter[status]" } },
                                        [_vm._v("Status")]
                                      ),
                                      _c(
                                        "gov-checkboxes",
                                        _vm._l(_vm.filters.status, function(
                                          status
                                        ) {
                                          return _c("gov-checkbox", {
                                            key:
                                              "Referrals::Index::Filters::Status::" +
                                              status.text,
                                            attrs: {
                                              id:
                                                "filter[status][" +
                                                status.value +
                                                "]",
                                              name:
                                                "filter[status][" +
                                                status.value +
                                                "]",
                                              label: status.text
                                            },
                                            model: {
                                              value: status.enabled,
                                              callback: function($$v) {
                                                _vm.$set(status, "enabled", $$v)
                                              },
                                              expression: "status.enabled"
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("ck-resource-listing-table", {
                    ref: "referralsTable",
                    attrs: {
                      uri: "/referrals",
                      params: _vm.params,
                      "default-sort": "-created_at",
                      columns: [
                        {
                          heading: "Reference no.",
                          sort: "reference",
                          render: function(referral) {
                            return referral.reference
                          }
                        },
                        {
                          heading: "Service",
                          sort: "service_name",
                          render: function(referral) {
                            return referral.service.name
                          }
                        },
                        {
                          heading: "Referred by",
                          render: function(referral) {
                            return referral.referee_name || "-"
                          }
                        },
                        {
                          heading: "Status",
                          render: function(referral) {
                            return _vm.$options.filters.status(referral.status)
                          }
                        },
                        {
                          heading: "Date submitted",
                          sort: "created_at",
                          render: function(referral) {
                            return (
                              "\n                  " +
                              _vm.formatDateTime(referral.created_at) +
                              "\n                  <br>\n                  " +
                              _vm.statusLastUpdated(referral) +
                              " days remaining\n                "
                            )
                          }
                        }
                      ],
                      "view-route": function(referral) {
                        return {
                          name: "referrals-confirmation",
                          params: { referral: referral.id }
                        }
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }